import $ from "jquery";
import {
  ProfileIconHoverOffStyle,
  ProfileIconHoverOnStyle,
  IconEllipseStyles,
  DropdownListStyles,
  DropdownItemsStyles,
} from "./../components/Navigation-Bar/JQUERY/InitialStyledQuery";
import {
  ontoggle,
  offtoggle,
  transitiontoggle,
} from "./../components/Navigation-Bar/JQUERY/Togglelogic";
import {
  profileiconfunction,
  portfolioiconfunction,
  portfoliodropdownfunction,
} from "./../components/Navigation-Bar/JQUERY/IconLogic";
import {
  searchbarfunction,
  searchbarmin481,
  searchbarmax481,
} from "./../components/Navigation-Bar/JQUERY/SearchBar";
import {
  sidenavhover,
  sidenavcomphover,
} from "./../components/Navigation-Bar/JQUERY/SideNavLogic";
import overlayClick from "./../functions/overlayClick";

// ------------|| SET GLOBAL VARIABLES ||-------------
export let globalvar = {
  sidebuffervalue: 0,
  mainbuffervalue: 0,
  searchbufferval: 0,
  rightpanelbufferval: "None",
  rightpaneltemp: "None",
  rightpanelheader: "None",
  rightpanelwidthval: 340,
  isOnDiv: false,
};

function setFilterMenuStyles(width, margin) {
  var element = document.querySelector(".filtermenunewwrapper");
  if (element) {
    element.style.width = width;
    element.style.margin = margin;
  } else {
    console.error('Element with class "filtermenunewwrapper" not found.');
  }
}

// ------------|| SET GLOBAL FUNCTIONS ||-------------
const rightPanelResizeFunction = (data) => {
  //const allData = data;

  let widthbuffer = 0;
  if (globalvar.rightpanelbufferval !== "None") {
    if (globalvar.rightpanelbufferval === "Global Filters") {
      $(".filtermenuvar").css({
        display: "flex",
      });
      $(".filter-icon").css({
        color: "#fbd665",
      });
      $(".clearfilters").css({
        display: "block",
      });
      //document.getElementById("rightpanelheader").innerHTML = "Global Filters";
    }
    $(".rightpanelonly").css({
      overflow: "visible",
      width: globalvar.rightpanelwidthval,
      paddingRight: 0,
      paddingLeft: 0,
      "min-width": 0,
    });
    $(".rightpanelclickable").css({
      width: 6,
    });
    $(".rightpanelheader").css({
      display: "block",
    });
  } else {
    $(".filtermenuvar").css({
      display: "none",
    });
    $(".clearfilters").css({
      display: "none",
    });
    $(".filter-icon").css({
      backgroundColor: "transparent",
      color: "#deebf7",
    });
    $(".rightpanelheader").css({
      display: "none",
    });
    globalvar.rightpanelbufferval = "None";
    $(".rightpanelonly").css({
      overflow: "visible",
      width: 0,
      paddingRight: 0,
      paddingLeft: 0,
      "min-width": 0,
    });
    $(".rightpanelclickable").css({
      width: 6,
    });
  }
  $(".filter-icon").hover(
    function () {
      $(".filter-icon").css({
        backgroundColor: "#1f2831",
      });
    },
    function () {
      $(".filter-icon").css({
        backgroundColor: "transparent",
      });
    }
  );
  $(".rightpanelcontainer").css({
    display: "block",
    margin: 0,
    padding: 0,
    zIndex: 20,
  });
  $(".rightpanelclickable").hover(
    function () {
      $(".rightpanelclickable").css({
        cursor: "w-resize",
      });
    },
    function () {
      $(".rightpanelclickable").css({
        cursor: "default",
      });
    }
  );
  const BORDER_SIZE = 7;
  const panel = document.getElementById("right_panel");
  const panel2 = document.getElementById("rightpanelclickable");
  let m_pos;
  function resize(e) {
    if (e.x > window.innerWidth) {
    } else {
      $(".rightpanelheader").css({
        display: "block",
      });
      $(".rightpanelclickable").css({
        width: 6,
      });
      $(".rightpanelonly").css({
        overflow: "visible",
        "min-width": "200px",
        "max-width": "1486px",
      });
      $(".clearfilters").css({
        display: "block",
      });
      if (
        document.getElementById("rightpanelheader").innerHTML ===
        "Global Filters"
      ) {
        $(".filter-icon").css({
          backgroundColor: "transparent",
          color: "#deebf7",
        });
        $(".filtermenuvar").css({
          display: "flex",
        });
        $(".forummenuvar").css({
          display: "none",
        });
        $(".clearfilters").css({
          display: "block",
        });
      }
      if (window.innerWidth - e.x < 71) {
        if (window.innerWidth - e.x < 70) {
          $(".rightpanelonly").css({
            overflow: "visible",
            width: 0,
            paddingRight: 0,
            paddingLeft: 0,
            "min-width": "0px",
          });
          globalvar.rightpanelbufferval = "None";
          /*           globalvar.rightpanelwidthval = 0;
          widthbuffer = 0; */
          $(".rightpanelclickable").css({
            width: 6,
          });
          $(".clearfilters").css({
            display: "none",
          });
          $(".rightpanelheader").css({
            display: "none",
          });
          m_pos = e.x + 70;
          e.preventDefault();
        }
      } else {
        if (window.innerWidth - e.x > 1488) {
        } else {
          const dx = m_pos - e.x;
          m_pos = e.x;
          $(".rightpanelclickable").css({
            cursor: "w-resize",
          });
          if (globalvar.rightpaneltemp === "Global Filters") {
            $(".filter-icon").css({
              color: "#fbd665",
            });
          }
          if (window.innerWidth - e.x < 200) {
            panel.style.width = "200px";
          } else {
            panel.style.width = parseInt(panel.style.width) + dx + "px";
          }

          e.preventDefault();
        }
      }
    }
  }
  panel2.addEventListener("mousedown", function (e) {
    if (e.offsetX < BORDER_SIZE) {
      m_pos = e.x;
      document.addEventListener("mousemove", resize, false);
      //document.getElementById("rightpanelheader").innerHTML = "Global Filters";
      globalvar.rightpaneltemp = "Global Filters";
      globalvar.rightpanelbufferval = "Global Filters";
      globalvar.rightpanelwidthval = widthbuffer;
      if (panel.style.width === "0px") {
        $(".rightpanelclickable").css({
          cursor: "w-resize",
        });
        m_pos = e.x - 200;
      } else {
        $(".rightpanelonly").css({
          overflow: "visible",
          "min-width": "200px",
          "max-width": "1486px",
        });
      }
      $(".rightpanelclickable").css({
        width: 6,
      });
      $(".rightpanelclickable").css({
        cursor: "w-resize",
        backgroundColor: "#313944",
      });
      $(".vellisepaneltoggler").css({
        color: "#deebf7",
      });
    }
    e.preventDefault();
  });

  document.addEventListener(
    "mouseup",
    function (e) {
      m_pos = e.x;
      document.removeEventListener("mousemove", resize, false);
      $(".rightpanelclickable").css({
        backgroundColor: "#1a2028",
      });

      if (panel.style.width === "0px") {
        $(".rightpanelonly").css({
          overflow: "visible",
          "min-width": "0px",
        });
        globalvar.rightpanelbufferval = "None";
      } else {
        $(".rightpanelonly").css({
          overflow: "visible",
          "min-width": "200px",
        });
        globalvar.rightpanelbufferval = globalvar.rightpaneltemp;
        globalvar.rightpanelwidthval = panel.style.width;
        widthbuffer = panel.style.width;
      }
      $(".vellisepaneltoggler").css({
        color: "#556171",
      });
      $(".rightpanelclickable").css({
        width: 6,
      });
      e.preventDefault();
    },
    false
  );

  const filterONFunction = (widthbuffer) => {
    $(".filter-icon").css({
      backgroundColor: "#1f2831",
      color: "#fbd665",
    });
    $(".filtermenuvar").css({
      display: "flex",
    });
    $(".rightpanelheader").css({
      display: "block",
    });
    $(".clearfilters").css({
      display: "block",
    });
    $(".rightpanelclickable").css({
      width: 6,
    });
    $(".rightpanelonly").css({
      overflow: "visible",
      width: widthbuffer === 0 ? globalvar.rightpanelwidthval : widthbuffer,
      paddingRight: 0,
      paddingLeft: 0,
      "min-width": "200px",
    });
    //document.getElementById("rightpanelheader").innerHTML = "Global Filters";
    globalvar.rightpaneltemp = "Global Filters";
    globalvar.rightpanelbufferval = "Global Filters";
    globalvar.rightpanelwidthval =
      widthbuffer === 0 ? globalvar.rightpanelwidthval : widthbuffer;
    panel.style.width = `${
      widthbuffer === 0 ? globalvar.rightpanelwidthval : widthbuffer
    }px`;
  };

  $(".filter-icon, .portfolio-icon-notification").click(function () {
    $(".account-dropdown").css({
      display: "none",
    });
    $(".portfolio-dropdown").css({
      display: "none",
    });
    $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    if (panel.style.width !== "0px") {
      $(".filter-icon").css({
        backgroundColor: "transparent",
        color: "#deebf7",
      });
      $(".filtermenuvar").css({
        display: "none",
      });
      $(".rightpanelonly").css({
        overflow: "visible",
        width: 0,
        paddingRight: 0,
        paddingLeft: 0,
        "min-width": "0px",
      });
      $(".rightpanelclickable").css({
        width: 6,
      });
      $(".clearfilters").css({
        display: "none",
      });
      $(".rightpanelheader").css({
        display: "none",
      });
      globalvar.rightpanelbufferval = "None";
      panel.style.width = "0px";
    } else {
      filterONFunction(widthbuffer);
    }
  });
};

// ------------|| RESPONSIVE DESIGN ||-------------

// ------------ INITIALIZE RESPONSIVE DESIGN -------------
export const ResponsiveDesignInitialize = () => {
  var width = $(window).width();
  var height = $(window).height();
  $(window).resize(ResponsiveDesignFunction);
  ResponsiveDesignFunction();
  if (globalvar.sidebuffervalue === 0) {
    ontoggle();
    $(".hamburger").css({
      "background-color": "#14181e",
      color: "#deebf7",
    });
    setFilterMenuStyles("calc(100% - 50px)", "0px 0px 12px 0px");

    globalvar.mainbuffervalue = 0;
  } else if (globalvar.sidebuffervalue === 1) {
    offtoggle();
    $(".hamburger").css({
      "background-color": "#14181e",
      color: "#fbd665",
    });
    setFilterMenuStyles("calc(100% - 205px)", "0px 0px 12px 155px");

    globalvar.mainbuffervalue = 1;
  }
};

// ------------ RESPONSIVE DESIGN MAIN LOGIC -------------
export const ResponsiveDesignFunction = () => {
  let w = $(window).width();
  if (window.matchMedia("(min-width: 481px)").matches) {
    if (globalvar.sidebuffervalue === 1) {
      $(".contentLB, .contentLB2").css({
        paddingLeft: 210,
        transition: "none",
      });
    } else {
      $(".contentLB, .contentLB2").css({
        paddingLeft: 55,
        transition: "none",
      });
    }
    $(
      ".sidebaronly, .sidenavstyledmain, .insightsbuffer, .toolsbuffer, .managedatabuffer"
    ).css({
      display: "inherit",
      opacity: 100,
    });
    searchbarmin481(globalvar);
  }

  if (window.matchMedia("(min-width: 767px)").matches) {
    $(".navbar-tabname, .navbar-tabname-morpheus").css({
      display: "flex",
    });
  }
  if (window.matchMedia("(min-width: 610px)").matches) {
    $(".viewingportviewing").css({
      display: "block",
    });
  }
  if (window.matchMedia("(min-width: 590px)").matches) {
    $(".portfolio-dropdown").css({
      right: "276px",
    });
  }
  if (window.matchMedia("(min-width: 531px)").matches) {
    $("#myContainerTickerTape").css({
      display: "block",
    });
  }
  if (window.matchMedia("(max-width: 767px)").matches) {
    $(".navbar-tabname, .navbar-tabname-morpheus").css({
      display: "none",
    });
  }
  if (window.matchMedia("(max-width: 610px)").matches) {
    $(".viewingportviewing").css({
      display: "block",
    });
  }
  if (window.matchMedia("(max-width: 590px)").matches) {
    $(".viewingportviewing").css({
      display: "none",
    });
    $(".portfolio-dropdown").css({
      right: "98px",
    });
  }
  if (window.matchMedia("(max-width: 531px)").matches) {
    $("#myContainerTickerTape").css({
      display: "none",
    });
  }
  if (window.matchMedia("(max-width: 481px)").matches) {
    $(".contentLB, .contentLB2").css({
      paddingLeft: 0,
      transition: "none",
    });
    $(
      ".sidebaronly, .sidenavstyledmain, .insightsbuffer, .toolsbuffer, .managedatabuffer"
    ).css({
      display: "none",
      opacity: 0,
    });
    searchbarmax481();
  }
  // SEARCH BAR STUFF
  if (globalvar.searchbufferval === 1) {
    if (window.matchMedia("(max-width: 1310px)").matches) {
      $(".search-bar").css({
        width: w - 810,
        transition: "none",
      });
    }
    if (window.matchMedia("(max-width: 848px)").matches) {
      $(".search-bar").css({
        width: 37,
        "font-size": 0,
      });
    }
    if (window.matchMedia("(min-width: 848px)").matches) {
      $(".search-bar").css({
        width: w - 810,
        "font-size": "1em",
      });
    }
    if (window.matchMedia("(min-width: 1310px)").matches) {
      $(".search-bar").css({
        width: 500,
        transition: "none",
      });
    }
  }
};

// ------------ RESPONSIVE DESIGN HAMBURGER LOGIC -------------
export const hamburgerfunction = () => {
  // ------------ HAMBURGER HOVER LOGIC -------------
  $(".hamburger").hover(
    function () {
      $(".hamburger").css({
        "background-color": "#1f2831",
        color: "#fbd665",
      });
    },
    function () {
      if (globalvar.mainbuffervalue === 1) {
        $(".hamburger").css({
          "background-color": "#14181e",
          color: "#fbd665",
        });
      } else {
        $(".hamburger").css({
          "background-color": "#14181e",
          color: "#deebf7",
        });
      }
    }
  );

  // ------------ HAMBURGER CLICK RESPONSIVE DESIGN LOGIC -------------
  $(".hamburger").click(function () {
    $(".account-dropdown").css({
      display: "none",
    });
    $(".portfolio-dropdown").css({
      display: "none",
    });
    $(".viewingportbutton").removeClass("viewingportbuttonhovered");
    $(".tooltip-bg").hide();
    $(
      ".dropdown-list-insights ,.dropdown-list-tools, .dropdown-list-import, account-dropdown"
    ).css({
      display: "none",
    });
    if (globalvar.mainbuffervalue === 1) {
      if (window.matchMedia("(min-width: 481px)").matches) {
        setFilterMenuStyles("calc(100% - 50px)", "0px 0px 12px 0px");
        globalvar.sidebuffervalue = 0;
      }
      if (window.matchMedia("(max-width: 1024px)").matches) {
        $(".contentLB, .contentLB2").css({
          paddingLeft: 55,
          marginRight: 0,
          transition: "none",
        });
      }
      if (window.matchMedia("(min-width: 1024px)").matches) {
        $(".contentLB, .contentLB2").css({
          paddingLeft: 55,
          transition: "none",
        });
        $(".hamburger").css({
          "background-color": "#14181e",
          color: "#deebf7",
          transition: "all 0.15s",
        });
      }
      if (window.matchMedia("(max-width: 481px)").matches) {
        $(".contentLB, .contentLB2").css({
          paddingLeft: 0,
          transition: "none",
        });
        $(
          ".sidebaronly, .sidenavstyledmain, .insightsbuffer, .toolsbuffer, .managedatabuffer"
        ).css({
          display: "none",
          opacity: 0,
        });
        $(".hamburger").css({
          "background-color": "#14181e",
          color: "#deebf7",
          transition: "none",
        });
      }
      if (window.matchMedia("(min-width: 481px)").matches) {
      }
      globalvar.mainbuffervalue = 0;
      ontoggle();
      transitiontoggle();
    } else {
      globalvar.mainbuffervalue = 1;
      if (window.matchMedia("(min-width: 481px)").matches) {
        setFilterMenuStyles("calc(100% - 205px)", "0px 0px 12px 155px");
        globalvar.sidebuffervalue = 1; // keeps the menu state open/closed only for larger screen sizes
      }
      if (window.matchMedia("(max-width: 1024px)").matches) {
        $(".contentLB, .contentLB2").css({
          paddingLeft: 210,
          marginRight: 0,
          transition: "none",
        });
      }
      if (window.matchMedia("(min-width: 1024px)").matches) {
        $(".contentLB, .contentLB2").css({
          paddingLeft: 210,
          transition: "none",
        });

        $(".hamburger").css({
          "background-color": "#1f2831",
          color: "#fbd665",
          transition: "all 0.15s",
        });
      }
      if (window.matchMedia("(max-width: 481px)").matches) {
        $(".contentLB, .contentLB2").css({
          paddingLeft: 0,
          transition: "none",
        });
        $(
          ".sidebaronly, .sidenavstyledmain, .insightsbuffer, .toolsbuffer, .managedatabuffer"
        ).css({
          display: "inherit",
          opacity: 100,
        });
        $(".hamburger").css({
          "background-color": "#1f2831",
          color: "#fbd665",
          transition: "none",
        });
      }
      if (window.matchMedia("(max-width: 481px)").matches) {
        //globalvar.sidebuffervalue = 0;
        //globalvar.mainbuffervalue = 0;
      }
      offtoggle();
      transitiontoggle();
    }
  });
};

export const alltoggle = () => {
  $(document).mouseup(function (e) {
    let accountv = $(".account-dropdown");
    let accountv2 = $(".profile-icon");
    let accountv3 = $(".portfolio-dropdown");
    let accountv4 = $(".viewingportbutton");
    if (
      !accountv.is(e.target) &&
      accountv.has(e.target).length === 0 &&
      accountv2.has(e.target).length === 0
    ) {
      if (e.target.id !== "addassetClassButton" && e.target.id !== "noclick") {
        accountv.css({ display: "none" });
      }
      ProfileIconHoverOnStyle(".profile-icon");
      if (accountv2.has(e.target).length === 0) {
        ProfileIconHoverOnStyle(".profile-icon");
        $("#profile-icon").css({ border: "2px solid #808080" });
      } else {
        ProfileIconHoverOffStyle(".profile-icon");
      }
    }

    if (
      !accountv3.is(e.target) &&
      accountv3.has(e.target).length === 0 &&
      accountv4.has(e.target).length === 0
    ) {
      if (e.target.id !== "addassetClassButton" && e.target.id !== "noclick") {
        accountv3.css({ display: "none" });
        $(".viewingportbutton").removeClass("viewingportbuttonhovered");
      }
    }
  });
};
// ------------|| PUTTING IT ALL TOGETHER ||-------------
const globaljquery = (history, location) => {
  sidenavcomphover(
    ".insightsbuffer",
    ".managedatabuffer",
    ".toolsbuffer",
    ".icon-caret-insights",
    ".icon-caret-tools",
    ".icon-caret-import",
    ".dropdown-list-insights",
    ".dropdown-list-tools",
    ".dropdown-list-import",
    globalvar
  );
  sidenavcomphover(
    ".toolsbuffer",
    ".managedatabuffer",
    ".insightsbuffer",
    ".icon-caret-tools",
    ".icon-caret-import",
    ".icon-caret-insights",
    ".dropdown-list-tools",
    ".dropdown-list-insights",
    ".dropdown-list-import",
    globalvar
  );
  sidenavcomphover(
    ".managedatabuffer",
    ".toolsbuffer",
    ".insightsbuffer",
    ".icon-caret-import",
    ".icon-caret-tools",
    ".icon-caret-insights",
    ".dropdown-list-import",
    ".dropdown-list-tools",
    ".dropdown-list-insights",
    globalvar
  );

  IconEllipseStyles(".icon-caret-insights");
  IconEllipseStyles(".icon-caret-tools");
  IconEllipseStyles(".icon-caret-import");

  DropdownListStyles(".dropdown-list-insights");
  DropdownListStyles(".dropdown-list-tools");
  DropdownListStyles(".dropdown-list-import");

  // ---------- random code ----------
  $(".filtermenuvarinner, .forummenuvarinner").addClass("right-panel-scroll");
  $(".filtermenuvarinner, .forummenuvarinner").css({
    position: "relative",
    top: "0px",
    marginTop: "0px",
    "z-index": "100001",
    width: "100%",
    height: "100%",
    marginBottom: 0,
    overflowY: "scroll",
  });
  $(".filtermenuvar, .forummenuvar").css({
    display: "none",
    top: "0px",
    marginTop: "0px",
    "z-index": "100001",
    width: "100%",
    marginLeft: 36,
    overflow: "auto",
    marginBottom: 0,
  });

  overlayClick();
  DropdownItemsStyles(".dropdown-items-entry-log");
  DropdownItemsStyles(".dropdown-items-yearly");
  DropdownItemsStyles(".dropdown-items-overall");
  DropdownItemsStyles(".dropdown-items-daily");
  DropdownItemsStyles(".dropdown-items-weekly");
  DropdownItemsStyles(".dropdown-items-monthly");
  DropdownItemsStyles(".dropdown-items-holding-time");
  DropdownItemsStyles(".dropdown-items-performance-ratios");
  DropdownItemsStyles(".dropdown-items-wins");
  DropdownItemsStyles(".dropdown-items-losses");
  DropdownItemsStyles(".dropdown-items-break-evens");
  DropdownItemsStyles(".dropdown-items-drawdown");
  DropdownItemsStyles(".dropdown-items-run-up");
  DropdownItemsStyles(".dropdown-items-Risk-Management");
  DropdownItemsStyles(".dropdown-items-mae-and-mfe");
  DropdownItemsStyles(".dropdown-items-efficiency");
  DropdownItemsStyles(".dropdown-items-fund-report");
  DropdownItemsStyles(".dropdown-items-simulator");
  DropdownItemsStyles(".dropdown-items-import-from-csv");
  DropdownItemsStyles(".dropdown-items-link-broker");
  DropdownItemsStyles(".dropdown-items-contact-us");
  DropdownItemsStyles(".dropdown-items-about");
  DropdownItemsStyles(".dropdown-items-privacy-policy");
  DropdownItemsStyles(".dropdown-items-terms-and-conditions");
  DropdownItemsStyles(".dropdown-items-initial-settings");
  DropdownItemsStyles(".dropdown-items-logbook-settings");
  DropdownItemsStyles(".dropdown-items-theme-settings");
  DropdownItemsStyles(".dropdown-items-manage-lists");
  DropdownItemsStyles(".dropdown-items-calendar");

  sidenavhover(".sidebaronly", globalvar);
  sidenavhover(".sidenavstyledmain", globalvar);
  sidenavhover(".insightsbuffer", globalvar);
  sidenavhover(".toolsbuffer", globalvar);
  sidenavhover(".managedatabuffer", globalvar);
  setFilterMenuStyles("calc(100% - 50px)", "0px 0px 12px 0px");

  searchbarfunction(globalvar);
  portfolioiconfunction(globalvar);
  profileiconfunction(globalvar, history, location);
  portfoliodropdownfunction(globalvar);
  //rightPanelResizeFunction(userData);
  ResponsiveDesignInitialize();
  hamburgerfunction();
  alltoggle();
};

export default globaljquery;
