import React from "react";
import { RightPanel } from "../PageTemplate/TemplateDivs.js";
import FilterMenuFunction from "./../Right-Panel/Filter-Menu/";
//import SharedTradeComponent from "./../Right-Panel/Forum";

import "./styling.scss";
import ClearFiltersButton from "./../Right-Panel/Filter-Menu/clearFilterMenuButton";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RightPanelFunction = (props) => {
  const userData = props.data;
  //const { user, /* isAuthenticated */ } = useAuth0();

  return (
    <div className="rightpanelcontainer">
      <div className="rightpanelclickable" id="rightpanelclickable">
        <FontAwesomeIcon icon={faEllipsisV} className="vellisepaneltoggler" />
      </div>
      <RightPanel className="rightpanelonly" id="right_panel">
{/*         <div className="rightpanelheader" id="rightpanelheader">
          Global Filters
        </div> */}
{/*         <FilterMenuFunction data={userData} />
 */}      </RightPanel>
      <ClearFiltersButton allData={userData} />
    </div>
  );
};

export default RightPanelFunction;
